import ApiService from '@/services/common/api.service'

const allowanceModule = {
  namespaced: true,
  state: () => ({
    errors: null,
    allowances: [],
    allowanceList: [],
    isLoading: false,
  }),
  getters: {
    getAllowanceList(state) {
      return state.allowanceList
    },
  },

  actions: {
    getAllowanceList(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/settings/allowance`, {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setAllowanceList', [
                ...context.state.allowanceList,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // Create Allowance Type
    saveAllowanceType(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          '/api/space-roketin/settings/allowance',
          form,
          true
        ).then(
          (response) => {
            context.commit('setAllowanceList', [
              ...context.state.allowanceList,
              response.data.data,
            ])
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    editAllowanceType(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.put(
          `/api/space-roketin/settings/allowance/${data.id}`,
          data,
          true
        ).then(
          (response) => {
            if (context?.state?.allowanceList) {
              const allData = context.state.allowanceList

              const filteredIndex = allData.findIndex((el) => el.id === data.id)

              const structuredData = {
                id: data.id,
                name: data.name,
                createdAt: data.createdAt,
                updatedAt: new Date(),
              }

              const result = [
                ...allData.slice(0, filteredIndex),
                structuredData,
                ...allData.slice(filteredIndex + 1),
              ]
              context.commit('setAllowanceList', [...result])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    deleteAllowanceType(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.delete(`/api/space-roketin/settings/allowance/${id}`).then(
          (response) => {
            if (context?.state?.allowanceList) {
              const data = context.state.allowanceList.filter(
                (el) => el.id !== id
              )
              context.commit('setAllowanceList', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    setLoading(state, data) {
      state.isLoading = data
    },
    setAllowanceList(state, data) {
      state.allowanceList = data
    },
  },
}

export default allowanceModule
