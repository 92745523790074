import ApiService from '@/services/common/api.service'

const employmentModule = {
  namespaced: true,
  state: () => ({
    errors: null,
    employeeGeneralList: [],
    employeeContractList: [],
    employeeArchiveList: [],
    isLoading: false,
  }),
  getters: {
    // To get employee list general
    getEmployeeGeneralList(state) {
      return state.employeeGeneralList
    },

    // To get employee list contract
    getEmployeeContractList(state) {
      return state.employeeContractList
    },

    // To get employee list archive
    getEmployeeArchiveList(state) {
      return state.employeeArchiveList
    },
  },
  actions: {
    // To get employee list general
    fetchEmployeeListGeneral(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/employee-directory/general`, {
          search: content.search,
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          isActive: content.isActive,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setEmployeeListGeneral', [
                ...context.state.employeeGeneralList,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To get employee list contract
    fetchEmployeeListContract(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/employee-directory/contract`, {
          search: content.search,
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setEmployeeListContract', [
                ...context.state.employeeContractList,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchEmployeeListArchive(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/employee/archive/archived', {
          search: content.search,
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setEmployeeListArchive', [
                ...context.state.employeeArchiveList,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    archiveEmployee(context, id, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          `/api/space-roketin/employee/archive/${id}`,
          form,
          true
        ).then(
          (response) => {
            if (context?.state?.employeeGeneralList) {
              const data = context.state.employeeGeneralList.filter(
                (el) => el.id !== id
              )
              context.commit('setEmployeeListGeneral', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    restoreEmployee(context, id, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          `/api/space-roketin/employee/archive/${id}/restore`,
          form,
          true
        ).then(
          (response) => {
            if (context?.state?.employeeArchiveList) {
              const data = context.state.employeeArchiveList.filter(
                (el) => el.id !== id
              )
              context.commit('setEmployeeListArchive', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },

    setEmployeeListGeneral(state, data) {
      state.employeeGeneralList = data
    },

    setEmployeeListContract(state, data) {
      state.employeeContractList = data
    },

    setEmployeeListArchive(state, data) {
      state.employeeArchiveList = data
    },
  },
}

export default employmentModule
