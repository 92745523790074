import ApiService from '@/services/common/api.service'

const overtime = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    overtimeRequestData: [],
    overtimeApprovalData: [],
  }),
  getters: {
    getOvertimeRequestData(state) {
      return state.overtimeRequestData
    },
    getOvertimeApprovalData(state) {
      return state.overtimeApprovalData
    },
  },

  actions: {
    // Fetch Request Data
    fetchOvertimeRequestData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/overtime/v3/request', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setOvertimeRequestData', [
                ...context.state.overtimeRequestData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchOvertimeApprovalData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          '/api/space-roketin/overtime/v3/approval?sort_field=updated_at&sort_order=asc',
          {
            perPage: content.perPage,
            sortField: content.sortField,
            sortOrder: content.sortOrder,
            page: content.page,
            search: content.search,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setOvertimeApprovalData', [
                ...context.state.overtimeApprovalData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // Create and save new overtime request
    saveOvertime(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          '/api/space-roketin/overtime/v3/request',
          form,
          true
        ).then(
          (response) => {
            context.commit('setOvertimeRequestData', [
              ...context.state.overtimeRequestData,
              response.data.data,
            ])

            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // Update overtime request
    updateOvertimeRequest(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        const { id, form } = data

        ApiService.post(
          `/api/space-roketin/overtime/v3/request/${id}`,
          form,
          true
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.overtimeRequestData
            ) {
              let OvertimeRequestList = [...context.state.overtimeRequestData]

              let index = OvertimeRequestList.findIndex((t) => t.id === id)

              if (index >= 0) {
                OvertimeRequestList[index] = response.data.data
              }

              context.commit('setOvertimeRequestData', [...OvertimeRequestList])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // Update Overtime Approval Request
    updateOvertimeApproval(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        const { id, status, reportToId } = content

        ApiService.put(`/api/space-roketin/overtime/v3/approval/${id}`, {
          status: status,
        }).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.overtimeApprovalData
            ) {
              let overtimeApprovalList = [...context.state.overtimeApprovalData]

              let index = overtimeApprovalList.findIndex((t) => t.id === id)

              if (index >= 0) {
                overtimeApprovalList[index].status = status

                let historyIndex = overtimeApprovalList[
                  index
                ].history.findIndex((h) => h.reportToId === reportToId)

                if (historyIndex >= 0) {
                  overtimeApprovalList[index].history[
                    historyIndex
                  ].status = status
                }
              }

              context.commit('updateApprovalStatus', [...overtimeApprovalList])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // Update Approval Status in Bulk
    massUpdateOvertimeApproval(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { status, ids } = content

        ApiService.put('/api/space-roketin/overtime/v3/approval/bulk', {
          status: status,
          ids: ids,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateApprovalStatus', {
                status,
                ids,
              })
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setOvertimeRequestData(state, data) {
      state.overtimeRequestData = data
    },
    setOvertimeApprovalData(state, data) {
      state.overtimeApprovalData = data
    },

    updateApprovalStatus(state, data) {
      state.overtimeApprovalData = data
    },

    massUpdateApprovalStatus(state, data) {
      const { status, ids } = data
      let overtimeApprovalList = [...state.overtimeApprovalData]

      overtimeApprovalList.forEach((t) => {
        if (ids.includes(t.id)) {
          t.status = status
        }
      })
      state.overtimeApprovalData = overtimeApprovalList
    },
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default overtime
