import ApiService from '@/services/common/api.service'

const attendance = {
  namespaced: true,
  state: () => ({
    attendanceData: null,
    isLoading: false,
  }),

  actions: {
    // to fetch my attendance data
    fetchAttendance(context, param) {
      let { startDate, endDate, userId } = param
      return new Promise((resolve, reject) => {
        //const arrOfDate = combinedDate.split('|')

        ApiService.get(`/api/space-roketin/attendance/log`, {
          startDate,
          endDate,
          userId: userId ? userId : '',
        }).then(
          (response) => {
            resolve(response.data.data)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    fetchLateAttendance(_, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/api/space-roketin/attendance/late`, params).then(
          (response) => {
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // fetch monthly attendance
    fetchMonthlyAttendance(_, data) {
      return new Promise((resolve, reject) => {
        const { startDate, endDate, isLoadAllEmployments } = data

        ApiService.get(`/api/space-roketin/attendance/monthly/log`, {
          start_date: startDate,
          end_date: endDate,
          is_load_all_employments: isLoadAllEmployments ? 1 : 0,
        }).then(
          (response) => {
            resolve(response.data.data)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // to fetch my team attendance data for a day
    fetchMyTeamAttendance() {
      return new Promise((resolve, reject) => {
        ApiService.get(`/api/space-roketin/attendance/myteam`).then(
          (response) => {
            resolve(response.data.data)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // to fetch my team's attendance data
    fetchTeamAttendanceHistory(_, combinedDate) {
      return new Promise((resolve, reject) => {
        const arrOfDate = combinedDate.split('|')

        ApiService.get(
          `/api/space-roketin/attendance/team/log?start_date=${arrOfDate[0]}&end_date=${arrOfDate[1]}`
        ).then(
          (response) => {
            resolve(response.data.data)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // to fetch my team's member
    fetchTeamMember() {
      return new Promise((resolve, reject) => {
        ApiService.get(`/api/space-roketin/user/my-team/name-only`).then(
          (response) => {
            resolve(response.data)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default attendance
