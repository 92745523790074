import ApiService from '@/services/common/api.service'

const logActivity = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    logActivityPersonal: [],
    logActivityTeam: [],
  }),
  getters: {
    getLogActivityPersonal(state) {
      return state.logActivityPersonal
    },
    getLogActivityTeam(state) {
      return state.logActivityTeam
    },
  },
  actions: {
    // To fetch log activity personal data
    fetchLogActivityPersonal(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/v3/request/adjustment', {
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          perPage: content.perPage,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setLogActivityPersonal', [
                ...context.state.logActivityPersonal,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To fetch log activity team data
    fetchLogActivityTeam(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/v3/request/adjustment/approval', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setLogActivityTeam', [
                ...context.state.logActivityTeam,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To update approval status
    updateLogActivityApprovalRequests(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        const { id, status, reportToId } = content

        ApiService.put(
          `/api/space-roketin/v3/request/adjustment/approval/${id}`,
          {
            status: status,
          }
        ).then(
          (response) => {
            if (response.status === 200 && context?.state?.logActivityTeam) {
              let logActivityTeamList = [...context.state.logActivityTeam]

              let index = logActivityTeamList.findIndex((t) => t.id === id)

              if (index >= 0) {
                logActivityTeamList[index].status = status

                let historyIndex = logActivityTeamList[index].history.findIndex(
                  (h) => h.reportToId === reportToId
                )

                if (historyIndex >= 0) {
                  // update history
                  logActivityTeamList[index].history[
                    historyIndex
                  ].status = status
                }
              }

              context.commit('updateApprovalStatus', [...logActivityTeamList])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To update approval status in bulk
    massUpdateLogActivityApprovalRequests(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { status, approvalIds } = content

        ApiService.put(
          '/api/space-roketin/v3/request/adjustment/approval/bulk',
          {
            status: status,
            approval_ids: approvalIds,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateApprovalStatus', {
                status,
                approvalIds,
              })
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    setLogActivityPersonal(state, data) {
      state.logActivityPersonal = data
    },
    setLogActivityTeam(state, data) {
      state.logActivityTeam = data
    },

    updateApprovalStatus(state, data) {
      state.logActivityTeam = data
    },

    massUpdateApprovalStatus(state, data) {
      const { status, approvalIds } = data
      let logActivityTeamList = [...state.logActivityTeam]

      logActivityTeamList.forEach((t) => {
        if (approvalIds.includes(t.id)) {
          t.status = status
        }
      })
      state.logActivityTeam = logActivityTeamList
    },

    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default logActivity
