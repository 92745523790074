import ApiService from '@/services/common/api.service'
import JwtService from '@/services/common/jwt.service'
import router from '@/router'
import store from '@/store'
import { deleteFCMToken } from '../../services/firebase/firebase-messaging'

const auth = {
  namespaced: true,
  state: () => ({
    errors: null,
    message: null,
    user: {},
    loggedIn: !!JwtService.getToken(),
    accessToken: null,
  }),
  getters: {
    currentUser(state) {
      return state.user
    },
    isLoggedIn(state) {
      return state.loggedIn
    },
    currentAccessToken(state) {
      return state.accessToken
    },
  },
  actions: {
    login(context, credentials) {
      return new Promise((resolve, reject) => {
        ApiService.post('/api/space-roketin/oauth/token', {
          grant_type: 'password',
          client_id: process.env.VUE_APP_CLIENT_ID,
          client_secret: process.env.VUE_APP_CLIENT_SECRET,
          username: credentials.email,
          password: credentials.password,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setAccessToken', response.data.accessToken)
              context.commit('setAuth', {
                user: response.data.user,
              })
              router.push({ path: credentials.redirect })

              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    async logout(context) {
      await deleteFCMToken()
      context.commit('purgeAuth')
      router.push({ path: '/auth/login' })
    },
    register() {
      return new Promise((resolve, reject) => {
        ApiService.post('/api/space-roketin/revoke', {}).then(
          (response) => {
            //*route register belum dibuat
            // router.push({ path: '/signin' })
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    getAccessToken(context) {
      return new Promise((resolve, reject) => {
        ApiService.reInitBaseURL()
        ApiService.get('/get-access-token').then(
          (response) => {
            if (response.status == 200) {
              context.commit('setAccessToken', response.data.accessToken)
              context.commit('setRefreshToken', response.data.refreshToken)
              resolve(response)
            }
          },
          (error) => {
            store.dispatch('auth/logout')
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setError(state, error) {
      state.errors = error
    },
    setAuth(state, user) {
      state.loggedIn = true
      state.user = user
      state.errors = {}
    },
    setAccessToken(state, data) {
      state.accessToken = data
    },
    setRefreshToken(state, data) {
      JwtService.saveToken(data)
    },
    purgeAuth(state) {
      state.loggedIn = false
      state.user = {}
      state.errors = {}
      JwtService.destroyToken()
    },
  },
}

export default auth
