const ID_TOKEN_KEY = 'id_token_space_id'
const VUE_APP_ENV = process.env.VUE_APP_ENV
const VUE_APP_HOST_DOMAIN = process.env.VUE_APP_HOST_DOMAIN

export const getToken = () => {
  return getCookie(ID_TOKEN_KEY)
}

export const saveToken = (token) => {
  setCookie(token)
}

export const destroyToken = () => {
  if (VUE_APP_ENV == 'local') {
    document.cookie =
      ID_TOKEN_KEY + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
  } else {
    document.cookie =
      ID_TOKEN_KEY +
      '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=' +
      VUE_APP_HOST_DOMAIN +
      ';'
  }
}

function setCookie(cvalue) {
  let d = new Date()
  d.setTime(d.getTime() + 3 * 24 * 60 * 60 * 1000) // 3 days
  let expires = 'expires=' + d.toUTCString()

  if (VUE_APP_ENV == 'local') {
    document.cookie = ID_TOKEN_KEY + '=' + cvalue + ';' + expires + ';path=/;'
  } else {
    document.cookie =
      ID_TOKEN_KEY +
      '=' +
      cvalue +
      ';' +
      expires +
      ';path=/;domain=' +
      VUE_APP_HOST_DOMAIN +
      ';'
  }
}

function getCookie(cname) {
  var name = cname + '='
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) == ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}

export default { getToken, saveToken, destroyToken }
