import ApiService from '@/services/common/api.service'
import moment from 'moment-timezone'

const user = {
  namespaced: true,
  state: () => ({
    user: null,
    latestAttendance: null,
    isLoading: false,
    userDetailData: null,
    peopleListData: [],
    peoplesFilter: null,
    uploadImageProgress: 0,
  }),
  getters: {
    getUserInfo(state) {
      return state.user
    },
    getLatestAttendance(state) {
      return state.latestAttendance
    },
    getIsLoading(state) {
      return state.isLoading
    },
    getUserDetailData(state) {
      return state.userDetailData
    },
    getPeopleListData(state) {
      return state.peopleListData
    },
    getPeoplesFilter(state) {
      return state.peoplesFilter
    },
    getUploadImageProgress(state) {
      return state.uploadImageProgress
    },
  },
  actions: {
    changeProfileImage(context, form) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/space-roketin/image/profile`,
          form,
          true,
          context,
          'setUploadImageProgress'
        ).then(
          (response) => {
            context.commit('setUploadImageProgress', 0)
            context.commit(
              'setProfilePictureUrl',
              response.data.profilePictureUrl
            )
            ApiService.resetHeader()
            resolve(response)
          },
          (error) => {
            context.commit('setUploadImageProgress', 0)
            ApiService.resetHeader()
            reject(error)
          }
        )
      })
    },

    loadUserFromToken(context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.get(`/api/space-roketin/token/user/detail`).then(
          (response) => {
            context.commit('setUser', response.data)
            context.commit('setTimezone', context.state.user.timezone)
            context.commit(
              'setLatestAttendance',
              response.data.latestAttendance
            )
            context.commit('setLoading', false)

            // set timezone

            resolve(response)
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
    checkIn(context, data) {
      return new Promise((resolve, reject) => {
        ApiService.post(`/api/space-roketin/attendance/checkin`, data).then(
          (response) => {
            context.commit('setLatestAttendance', response.data.data)
            context.commit('setWorkingStatus', 'online')
            // set is active in other company to false
            context.commit('setIsActiveInOtherCompany', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    checkOut(context, data) {
      return new Promise((resolve, reject) => {
        ApiService.post(`/api/space-roketin/attendance/checkout`, data).then(
          (response) => {
            context.commit('setWorkingStatus', 'offline')
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    getWorkDuration(context, date) {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/api/space-roketin/attendance/log?start_date=${date}&end_date=${date}`
        ).then(
          (response) => {
            resolve(
              response.data.data[0] && response.data.data[0].durationPerDay
                ? response.data.data[0].durationPerDay
                : 0
            )
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // To get user detail based on user id
    getUserDetailById(context, userId) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/user/detail?id=${userId}`).then(
          (response) => {
            console.log(response)
            context.commit('setUserDetailData', response.data)
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To fetch people data
    fetchPeopleList(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/user/v3', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
          departmentIds: content.departmentIds,
          statusIds: content.statusIds,
          locationIds: content.locationIds,
          isRemote: content.isRemote,
        }).then(
          (response) => {
            if (response.status === 200) {
              if (content.isFiltering) {
                context.commit('setPeopleList', response.data.data)
              } else {
                context.commit('setPeopleList', [
                  ...context.state.peopleListData,
                  ...response.data.data,
                ])
              }

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To fetch filter data for page people
    fetchPeoplesFilter(context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/user/v3/filter-options').then(
          (response) => {
            if (response.status === 200) {
              context.commit('setPeoplesFilter', response.data)

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setUser(state, data) {
      state.user = data
    },
    setLoading(state, data) {
      state.isLoading = data
    },
    setLatestAttendance(state, data) {
      state.latestAttendance = data
    },
    setWorkingStatus(state, data) {
      state.user.workingStatus = data
    },
    setUserDetailData(state, data) {
      state.userDetailData = data
    },
    setPeopleList(state, data) {
      state.peopleListData = data
    },
    setPeoplesFilter(state, data) {
      state.peoplesFilter = data
    },
    setProfilePictureUrl(state, url) {
      state.user.profilePictureUrl = url
    },
    setUploadImageProgress(state, val) {
      state.uploadImageProgress = val
    },
    setTimezone(state, val) {
      state.user.timezone = val
      if (state.user.timezone) {
        moment.tz.setDefault(state.user.timezone)
      } else {
        moment.tz.setDefault()
      }
    },
    setIsActiveInOtherCompany(state, val) {
      state.user.isActiveInOtherCompany = val
    },
  },
}

export default user
