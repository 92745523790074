const state = () => ({
  timeOffList: [
    {
      full_name: 'Jesse Simmons',
      permission_type: 'sick',
      date: '2016-10-15 13:43:27',
      reason: 'Urusan Keluarga',
      status: 'Approved',
      action: 'Done',
      attachment: '',
    },
    {
      full_name: 'John Jacobs',
      permission_type: 'sick',
      date: '2016-12-15 06:00:53',
      reason: 'Urusan Keluarga',
      status: 'Approved',
      action: 'Done',
      attachment: '',
    },
    {
      full_name: 'Tina Gilbert',
      permission_type: 'sick',
      date: '2016-04-26 06:26:28',
      reason: 'Urusan Keluarga',
      status: 'Approved',
      action: 'Done',
      attachment: '',
    },
    {
      full_name: 'Clarence Flores',
      permission_type: 'sick',
      date: '2016-04-10 10:28:46',
      reason: 'Urusan Keluarga',
      status: 'Approved',
      action: 'Done',
      attachment: '',
    },
    {
      full_name: 'Anne Lee',
      permission_type: 'sick',
      date: '2016-12-06 14:38:38',
      reason: 'Urusan Keluarga',
      status: 'FeApproved',
      action: 'Done',
      attachment: '',
    },
  ],
})

const mutations = {
  addTimeOff(state, data) {
    let timeOffList = state.timeOffList
    timeOffList.push(data)
    state.timeOffList = timeOffList
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
