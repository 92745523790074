<template>
  <router-view></router-view>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  getTokenFCM,
  requestPermission,
} from './services/firebase/firebase-messaging'
export default {
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/isLoggedIn',
    }),
  },
  watch: {
    async isLoggedIn() {
      await this.subscribeFCM()
    },
  },
  methods: {
    async subscribeFCM() {
      try {
        if (this.isLoggedIn) {
          let isPermitted = await requestPermission()
          if (isPermitted) {
            await getTokenFCM()
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
  async created() {
    await this.subscribeFCM()
  },
}
</script>
