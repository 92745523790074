import ApiService from '@/services/common/api.service'

const announcement = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    announcementIndex: [],
    announcementPublished: [],
    announcementDetail: {},
  }),

  getters: {
    getAnnouncementIndex(state) {
      return state.announcementIndex
    },
    getAnnouncementPublished(state) {
      return state.announcementPublished
    },
  },

  actions: {
    fetchAnnouncementIndex(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/announcement/v3', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setAnnouncementIndex', [
                ...context.state.announcementIndex,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchAnnouncementPublished(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/announcement/v3/published', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setAnnouncementIndex', [
                ...context.state.announcementIndex,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchAnnouncementDetail(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          `/api/space-roketin/announcement/v3/${content.selectedAnnouncement}`
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setAnnouncementDetail', response.data.data)
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    saveAnnouncement(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.setHeaderMultipartFormData()

        ApiService.post(`/api/space-roketin/announcement/v3`, form, true).then(
          (response) => {
            context.commit('setAnnouncementIndex', [
              ...context.state.announcementIndex,
              response.data.data,
            ])
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    updateAnnouncement(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        const { id, form } = data

        ApiService.post(
          `/api/space-roketin/announcement/v3/${id}`,
          form,
          true
        ).then(
          (response) => {
            if (response.status === 200 && context?.state?.announcementIndex) {
              let AnnouncementIndexList = [...context.state.announcementIndex]

              let index = AnnouncementIndexList.findIndex((t) => t.id === id)

              if (index >= 0) {
                AnnouncementIndexList[index] = response.data.data
              }

              context.commit('setAnnouncementIndex', [...AnnouncementIndexList])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    deleteAnnouncement(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.delete(`/api/space-roketin/announcement/v3/${id}`).then(
          (response) => {
            if (context?.state?.announcementIndex) {
              const data = context.state.announcementIndex.filter(
                (el) => el.id !== id
              )

              context.commit('setAnnouncementIndex', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setAnnouncementIndex(state, data) {
      state.announcementIndex = data
    },

    setAnnouncementPublished(state, data) {
      state.announcementPublished = data
    },

    setAnnouncementDetail(state, data) {
      state.announcementDetail = data
    },

    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default announcement
