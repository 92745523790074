import ApiService from '@/services/common/api.service'

const finalApproval = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    userLists: [],
    finalApprovalLists: [],
  }),
  getters: {
    getUserLists(state) {
      return state.userLists
    },
    getFinalApprovalLists(state) {
      return state.finalApprovalLists
    },
  },
  actions: {
    // To fetch user lists for final approval
    fetchUserLists(context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          '/api/space-roketin/final-approval-request/show-select-user'
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setUserLists', [...response.data.data])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To fetch existing final approval lists
    fetchExistingFinalApprovalLists(context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/final-approval-request/list').then(
          (response) => {
            if (response.status === 200) {
              context.commit('setFinalApprovalLists', [...response.data.data])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To update final approval user
    updateFinalApprovalUser(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.setHeaderMultipartFormData()

        ApiService.post(
          `/api/space-roketin/final-approval-request`,
          form,
          true
        ).then(
          (response) => {
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    setUserLists(state, data) {
      state.userLists = data
    },

    setFinalApprovalLists(state, data) {
      state.finalApprovalLists = data
    },

    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default finalApproval
