import ApiService from '@/services/common/api.service'

const wfoPermission = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    wfoPermissionRequestData: [],
    wfoPermissionApprovalData: [],
  }),
  getters: {
    getWFOPermissionRequestData(state) {
      return state.wfoPermissionRequestData
    },
    getWFOPermissionApprovalData(state) {
      return state.wfoPermissionApprovalData
    },
  },
  actions: {
    // To fetch request data
    fetchWFOPermissionRequestData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/wfo/request', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setWFOPermissionRequestData', [
                ...context.state.wfoPermissionRequestData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To fetch managements' approval data
    fetchWFOPermissionApprovalData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/wfo/approval', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setWFOPermissionApprovalData', [
                ...context.state.wfoPermissionApprovalData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To create new WFO Permission request
    saveWFOPermission(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.setHeaderMultipartFormData()

        ApiService.post(`/api/space-roketin/wfo/request`, form, true).then(
          (response) => {
            context.commit('setWFOPermissionRequestData', [
              ...context.state.wfoPermissionRequestData,
              response.data.data,
            ])

            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // To update approval status
    updateWFOPermissionApproval(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        const { id, status, reportToId } = content

        ApiService.put(`/api/space-roketin/wfo/approval/${id}`, {
          status: status,
        }).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.wfoPermissionApprovalData
            ) {
              let wfoPermissionApprovalList = [
                ...context.state.wfoPermissionApprovalData,
              ]

              let index = wfoPermissionApprovalList.findIndex(
                (t) => t.id === id
              )

              if (index >= 0) {
                wfoPermissionApprovalList[index].status = status

                let historyIndex = wfoPermissionApprovalList[
                  index
                ].history.findIndex((h) => h.reportToId === reportToId)

                if (historyIndex >= 0) {
                  // update history
                  wfoPermissionApprovalList[index].history[
                    historyIndex
                  ].status = status
                }
              }

              context.commit('updateApprovalStatus', [
                ...wfoPermissionApprovalList,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To update approval status in bulk
    massUpdateWFOPermissionApproval(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { status, ids } = content

        ApiService.put('/api/space-roketin/wfo/approval/mass-update', {
          status: status,
          ids: ids,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateApprovalStatus', {
                status,
                ids,
              })
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To cancel personal request
    cancelWFOPermissionRequest(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)
        ApiService.setHeaderMultipartFormData()

        ApiService.post(
          `/api/space-roketin/wfo/request/${id}`,
          {
            _METHOD: 'PUT',
            status: 'canceled',
          },
          true
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.wfoPermissionRequestData
            ) {
              let wfoPermissionRequestList = [
                ...context.state.wfoPermissionRequestData,
              ]

              let index = wfoPermissionRequestList.findIndex((t) => t.id === id)

              if (index >= 0) {
                wfoPermissionRequestList[index].status = 'canceled'
              }

              context.commit('setWFOPermissionRequestData', [
                ...wfoPermissionRequestList,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    setWFOPermissionRequestData(state, data) {
      state.wfoPermissionRequestData = data
    },
    setWFOPermissionApprovalData(state, data) {
      state.wfoPermissionApprovalData = data
    },

    updateApprovalStatus(state, data) {
      state.wfoPermissionApprovalData = data
    },

    massUpdateApprovalStatus(state, data) {
      const { status, ids } = data
      let wfoPermissionApprovalList = [...state.wfoPermissionApprovalData]

      wfoPermissionApprovalList.forEach((t) => {
        if (ids.includes(t.id)) {
          t.status = status
        }
      })
      state.wfoPermissionApprovalData = wfoPermissionApprovalList
    },
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default wfoPermission
