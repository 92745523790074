import Vue from 'vue'
// import VueRouter from "vue-router";
import './assets/sass/app.scss'
import './registerServiceWorker'
import App from './App'
import './assets/sass/app.scss'
import Vue2OrgTree from 'vue2-org-tree'

import FrontendKit from '@roketin-library/frontend-kit'
import VueMeta from 'vue-meta'

import router from './router'
import store from './store'
import ApiService from '@/services/common/api.service'

import './plugins/validator'
import './plugins/infinite-scroll'
import './plugins/vertical-timeline'
import mixins from './mixins'
import firebaseMessaging from './services/firebase/firebase-messaging'
import Hotjar from 'vue-hotjar'

Vue.config.productionTip = false

Vue.use(FrontendKit, {
  // platform: 'html',
})

Vue.use(VueMeta)

Vue.use(Vue2OrgTree)

ApiService.init()

/*
 * Now you can access FCM instance everywhere in your application by calling this.$messaging.
 */
Vue.prototype.$messaging = firebaseMessaging

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')

Vue.mixin(mixins)
Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_ID,
  isProduction: true,
})
