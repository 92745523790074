// ROUTES FOR NEW FEATURES DEMO
export default {
  path: '/test',
  component: () => import('./views/index.vue'),
  children: [
    {
      path: 'r-page-modal',
      component: () => import('./views/RPageModal/index.vue'),
      meta: {
        title: 'RPageModal - Test - Rise by Roketin',
      },
      children: [
        {
          path: 'create',
          component: () => import('./views/RPageModal/Create.vue'),
          meta: {
            title: 'Modal Example - Test - Rise by Roketin',
          },
        },
      ],
    },
    {
      path: 'alert',
      component: () => import('./views/Alert/index.vue'),
      meta: {
        title: 'Alert - Test - Rise by Roketin',
      },
    },
    {
      path: 'r-page',
      component: () => import('./views/RPage/index.vue'),
      meta: {
        title: 'RPage - Test - Rise by Roketin',
      },
    },
    {
      path: 'buttons',
      component: () => import('./views/Buttons/index.vue'),
      meta: {
        title: 'Buttons - Test - Rise by Roketin',
      },
    },
    {
      path: 'textfield',
      component: () => import('./views/TextField/index.vue'),
      meta: {
        title: 'Text Field - Test - Rise by Roketin',
      },
    },
    {
      path: 'dropdown',
      component: () => import('./views/Dropdown/index.vue'),
      meta: {
        title: 'Dropdown - Test - Rise by Roketin',
      },
    },
    {
      path: 'autocomplete',
      component: () => import('./views/Autocomplete/index.vue'),
      meta: {
        title: 'Autocomplete - Test - Rise by Roketin',
      },
    },
    {
      path: 'steps',
      component: () => import('./views/Steps/index.vue'),
      meta: {
        title: 'Steps - Test - Rise by Roketin',
      },
    },
    {
      path: 'menu-tab',
      component: () => import('./views/RMenuTab/index.vue'),
      meta: {
        title: 'RMenuTab - Test - Rise by Roketin',
      },
    },
  ],
}
