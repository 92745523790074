import ApiService from '@/services/common/api.service'
import router from '@/router'

const timeOffModule = {
  namespaced: true,
  state: () => ({
    errors: null,
    timeoffs: [],
    timeOffApprovalList: [],
    isLoading: false,
    timeOffTypes: [],
    allTimeOffTypes: [],
  }),
  getters: {
    // Fot get time offs
    getTimeoffs(state) {
      return state.timeoffs
    },
    getTimeOffTypes(state) {
      return state.timeOffTypes
    },
    getTimeoffApprovalList(state) {
      return state.timeOffApprovalList
    },
    getAllTimeOffTypesList(state) {
      return state.allTimeOffTypes
    },
  },
  actions: {
    getTimeOffTypes(context) {
      return new Promise((resolve, reject) => {
        ApiService.get('/api/space-roketin/time-off/get-filter-time-off').then(
          (response) => {
            if (response.status === 200) {
              context.commit('setTimeOffTypes', response.data)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    updateStatusApproval(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        const { id, status, reportToId, reason } = content
        ApiService.put(`/api/space-roketin/time-off/v3/approval/${id}`, {
          status,
          reason,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('updateStatusApproval', {
                id,
                status,
                reportToId,
                reason,
              })
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // For get timeoffs
    fetchListTimeOffApproval(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/space-roketin/time-off/v3/approval`, {
          perPage: content.perPage,
          page: content.page,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setTimeoffsApprovalList', [
                ...context.state.timeOffApprovalList,
                ...response.data.data,
              ])
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // For get timeoffs
    fetchMyRequestTimeoffs(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)
        ApiService.get(`/api/space-roketin/time-off/v3/request`, {
          perPage: content.perPage,
          page: content.page,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setTimeoffs', [
                ...context.state.timeoffs,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            // context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // For create timeoff
    saveTimeoff(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()
        ApiService.post(
          `/api/space-roketin/time-off/v3/request`,
          form,
          true
        ).then(
          (response) => {
            context.commit('setTimeoffs', [
              response.data.data,
              ...context.state.timeoffs,
            ])
            context.commit('setLoading', false)
            router.push({ path: '/request/time-off' })
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // For update timeoff
    updateTimeoff(context, data) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        const { form, id } = data
        ApiService.post(
          `/api/space-roketin/time-off/v3/request/${id}`,
          form,
          true
        ).then(
          (response) => {
            context.commit('updateSpecificRequestTimeOff', response.data.data)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // For approve time off
    massUpdateTimeOffApproval(context, content) {
      return new Promise((resolve, reject) => {
        const { ids, status } = content
        ApiService.put('/api/space-roketin/time-off/v3/approval/mass-update', {
          ids,
          status,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateStatusTimeOffApproval', {
                ids,
                status,
              })
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // For cancel time off
    cancelTimeoff(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post(
          '/api/space-roketin/time-off/v3/request/' +
            content.id +
            '/cancel-time-off',
          {
            reason: content.reason,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setLoading', false)
              context.commit('cancelSpecificRequestTimeOff', response.data)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // To get my and team's time off data
    getAllTimeOffs(_, date) {
      return new Promise((resolve, reject) => {
        ApiService.get(
          `/api/space-roketin/calendar-dashboard?month=${date[0]}&year=${date[1]}`
        ).then(
          (response) => {
            if (response.status === 200) {
              resolve(response.data.data)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // To get all time off type
    getAllTimeOffTypes(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/time-off-type`, {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setAllTimeOffTypes', [
                ...context.state.allTimeOffTypes,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To create timeoff type
    saveTimeoffType(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        ApiService.post(
          `/api/space-roketin/time-off/time-off-type/store`,
          form,
          true
        ).then(
          (response) => {
            context.commit('setAllTimeOffTypes', [
              ...context.state.allTimeOffTypes,
              response.data,
            ])
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // To edit timeoff type
    editTimeoffType(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        ApiService.put(
          `/api/space-roketin/time-off/time-off-type/${data.id}/update`,
          data,
          true
        ).then(
          (response) => {
            if (context?.state?.allTimeOffTypes) {
              const allData = context.state.allTimeOffTypes

              const filteredIndex = allData.findIndex((el) => el.id === data.id)

              const structuredData = {
                id: data.id,
                name: data.name,
                paidDays: data.paid_days,
                timeType: data.time_type,
                createdAt: data.createdAt,
                updatedAt: new Date(),
              }

              const result = [
                ...allData.slice(0, filteredIndex),
                structuredData,
                ...allData.slice(filteredIndex + 1),
              ]
              context.commit('setAllTimeOffTypes', [...result])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // To delete timeoff type
    deleteTimeoffType(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.delete(
          `/api/space-roketin/time-off/time-off-type/${id}/delete`
        ).then(
          (response) => {
            if (context?.state?.allTimeOffTypes) {
              const data = context.state.allTimeOffTypes.filter(
                (el) => el.id !== id
              )
              context.commit('setAllTimeOffTypes', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    setTimeOffTypes(state, data) {
      state.timeOffTypes = data
    },
    // For set laoding
    setLoading(state, data) {
      state.isLoading = data
    },

    // For set timeoffs
    setTimeoffs(state, data) {
      state.timeoffs = data
    },
    setTimeoffsApprovalList(state, data) {
      state.timeOffApprovalList = data
    },
    cancelSpecificRequestTimeOff(state, data) {
      let timeOffRequestList = [...state.timeoffs]
      let index = timeOffRequestList.findIndex((t) => t.id === data.id)
      if (index >= 0) {
        timeOffRequestList[index].requestStatus = 'canceled'
        state.timeoffs = timeOffRequestList
      }
    },
    removeSpecificFileFromRequestTimeoff(state, data) {
      let { timeOffId, fileId } = data
      let timeOffRequestList = [...state.timeoffs]
      let index = timeOffRequestList.findIndex((t) => t.id === timeOffId)
      if (index >= 0) {
        timeOffRequestList[index].files = timeOffRequestList[
          index
        ].files.filter((f) => f.id !== fileId)
        state.timeoffs = timeOffRequestList
      }
    },
    updateSpecificRequestTimeOff(state, data) {
      let timeOffRequestList = [...state.timeoffs]
      let index = timeOffRequestList.findIndex((t) => t.id === data.id)
      if (index >= 0) {
        timeOffRequestList[index] = data
        state.timeoffs = timeOffRequestList
      }
    },
    updateStatusApproval(state, data) {
      const { id, status, reportToId, reason } = data
      let timeOffApprovalList = [...state.timeOffApprovalList]
      let index = timeOffApprovalList.findIndex((t) => t.id === id)
      if (index >= 0) {
        timeOffApprovalList[index].approvalStatus = status
        timeOffApprovalList[index].approvalReason = reason
        timeOffApprovalList[index].approvable = false

        let historyIndex = timeOffApprovalList[index].history.findIndex(
          (h) => h.reportToId === reportToId
        )
        if (historyIndex >= 0) {
          // update history
          timeOffApprovalList[index].history[historyIndex].status = status
          timeOffApprovalList[index].history[
            historyIndex
          ].approvalReason = reason
        }
        state.timeOffApprovalList = timeOffApprovalList
      }
    },
    massUpdateStatusTimeOffApproval(state, data) {
      const { ids, status } = data
      let timeOffApprovalList = [...state.timeOffApprovalList]
      timeOffApprovalList.forEach((t) => {
        if (ids.includes(t.id)) {
          t.approvalStatus = status
          t.approvable = false
        }
      })
      state.timeOffApprovalList = timeOffApprovalList
    },

    setAllTimeOffTypes(state, data) {
      state.allTimeOffTypes = data
    },
  },
}

export default timeOffModule
