import ApiService from '@/services/common/api.service'
import {deleteCookie,  setCookie} from "../../services/util";


const auth = {
  namespaced: true,
  actions: {
    storeFCMToken(context, credentials) {
      const { token } = credentials
      return new Promise((resolve, reject) => {
        ApiService.post('/api/space-roketin/firebase-token', {
          token,
        }).then(
          (response) => {
            if(response.status === 201){
              // save fcm token for 7 days
              setCookie(process.env.VUE_APP_COOKIE_FCM_NAME, token, 7 * 24 * 60 * 60 * 1000, '/')
            }
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    deleteToken(context, credential) {
      let {token} = credential
      return new Promise((resolve, reject) => {
        ApiService.delete('/api/space-roketin/firebase-token', { token }).then(
          (response) => {
            deleteCookie(process.env.VUE_APP_COOKIE_FCM_NAME)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },
}

export default auth
