import ApiService from '@/services/common/api.service'

const attendance = {
  namespaced: true,
  state: () => ({
    attendanceData: null,
    isLoading: false,
  }),

  actions: {
    fetchOutstandingRequest(_, params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/api/space-roketin/outstanding-request`, params).then(
          (response) => {
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default attendance
