import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/en.json'
import moment from 'moment'

// import all rules https://vee-validate.logaretm.com/v3/guide/rules.html#installing-all-rules
Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule], // assign message
  })
})

// kalo mau custom message ato menambahkan custom rules harus pake cara seperti ini
// https://vee-validate.logaretm.com/v3/guide/rules.html#importing-the-rules
extend('date_higher_than', {
  params: ['target'],
  validate(value, { target }) {
    let momentEndDate = new moment(value)
    let momentStartDate = new moment(target)
    return momentEndDate.diff(momentStartDate, 'days') >= 0
  },
  message: "'end_date' date must higher than 'start_date'",
})
