import ApiService from '@/services/common/api.service'

const quotes = {
  namespaced: true,
  state: () => ({
    errors: null,
    quotesList: [],
    quoteRise: {},
    isLoading: false,
  }),

  getters: {
    getQuotesList(state) {
      return state.quotesList
    },
    getQuoteRise(state) {
      return state.quoteRise
    },
  },

  actions: {
    fetchQuotesList(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/settings/quote`, {
          perPage: content.perPage,
          page: content.page,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setQuotesList', [
                ...context.state.quotesList,
                ...response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchQuoteRise(context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(`/api/space-roketin/settings/quote/universe`).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setQuoteRise', [
                context.state.quoteRise,
                response.data.data,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    saveQuote(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post('/api/space-roketin/settings/quote', form, true).then(
          (response) => {
            context.commit('setQuotesList', [
              ...context.state.quotesList,
              response.data.data,
            ])
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    editQuote(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { id, form } = data

        ApiService.put(
          `/api/space-roketin/settings/quote/${id}`,
          form,
          true
        ).then(
          (response) => {
            if (response.status === 200 && context?.state?.quotesList) {
              let QuotesIndexList = [...context.state.quotesList]

              let index = QuotesIndexList.findIndex((t) => t.id === id)

              if (index >= 0) {
                QuotesIndexList[index] = response.data.data
              }

              context.commit('setQuotesList', [...QuotesIndexList])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    deleteQuote(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.delete(`/api/space-roketin/settings/quote/${id}`).then(
          (response) => {
            if (context?.state?.quotesList) {
              const data = context.state.quotesList.filter((el) => el.id === id)

              context.commit('setQuotesList', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setLoading(state, data) {
      state.isLoading = data
    },
    setQuotesList(state, data) {
      state.quotesList = data
    },
    setQuoteRise(state, data) {
      state.quoteRise = data
    },
  },
}

export default quotes
