import ApiService from '@/services/common/api.service'

const changeSchedule = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    changeScheduleRequestData: [],
    changeScheduleApprovalData: [],
  }),
  getters: {
    getChangeScheduleRequestData(state) {
      return state.changeScheduleRequestData
    },
    getChangeScheduleApprovalData(state) {
      return state.changeScheduleApprovalData
    },
  },
  actions: {
    // To fetch request data
    fetchChangeScheduleRequestData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/change-schedule/request', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setChangeScheduleRequestData', [
                ...context.state.changeScheduleRequestData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To fetch managements' approval data
    fetchChangeScheduleApprovalData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/change-schedule/approval', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setChangeScheduleApprovalData', [
                ...context.state.changeScheduleApprovalData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To create new WFO Permission request
    saveChangeSchedule(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        ApiService.post(
          `/api/space-roketin/change-schedule/request`,
          form,
          true
        ).then(
          (response) => {
            context.commit('setChangeScheduleRequestData', [
              ...context.state.changeScheduleRequestData,
              response.data.data,
            ])

            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // To update personal request
    updateChangeScheduleRequest(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        const { id, form } = data

        ApiService.post(
          `/api/space-roketin/change-schedule/request/${id}`,
          form,
          true
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.changeScheduleRequestData
            ) {
              let ChangeScheduleRequestList = [
                ...context.state.changeScheduleRequestData,
              ]

              let index = ChangeScheduleRequestList.findIndex(
                (t) => t.id === id
              )

              if (index >= 0) {
                ChangeScheduleRequestList[index] = response.data.data
              }

              context.commit('setChangeScheduleRequestData', [
                ...ChangeScheduleRequestList,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // To update approval status
    updateChangeScheduleApproval(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        const { id, status, reportToId } = content

        ApiService.put(`/api/space-roketin/change-schedule/approval/${id}`, {
          status: status,
        }).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.changeScheduleApprovalData
            ) {
              let changeScheduleApprovalList = [
                ...context.state.changeScheduleApprovalData,
              ]

              let index = changeScheduleApprovalList.findIndex(
                (t) => t.id === id
              )

              if (index >= 0) {
                changeScheduleApprovalList[index].status = status

                let historyIndex = changeScheduleApprovalList[
                  index
                ].history.findIndex((h) => h.reportToId === reportToId)

                if (historyIndex >= 0) {
                  // update history
                  changeScheduleApprovalList[index].history[
                    historyIndex
                  ].status = status
                }
              }

              context.commit('updateApprovalStatus', [
                ...changeScheduleApprovalList,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To update approval status in bulk
    massUpdateChangeScheduleApproval(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { status, ids } = content

        ApiService.put(
          '/api/space-roketin/change-schedule/approval/mass-update',
          {
            status: status,
            ids: ids,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateApprovalStatus', {
                status,
                ids,
              })
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    setChangeScheduleRequestData(state, data) {
      state.changeScheduleRequestData = data
    },
    setChangeScheduleApprovalData(state, data) {
      state.changeScheduleApprovalData = data
    },

    updateApprovalStatus(state, data) {
      state.changeScheduleApprovalData = data
    },

    massUpdateApprovalStatus(state, data) {
      const { status, ids } = data
      let changeScheduleApprovalList = [...state.changeScheduleApprovalData]

      changeScheduleApprovalList.forEach((t) => {
        if (ids.includes(t.id)) {
          t.status = status
        }
      })
      state.changeScheduleApprovalData = changeScheduleApprovalList
    },
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default changeSchedule
