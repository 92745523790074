//firebase-messaging.js
import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
import { getCookie, showToast } from '../util'
import store from '../../store/index'
/*
 *Because the service worker is running on another thread and not on the main one,
 * therefore we cannot pass the instance to our main application.
 * https://dev.to/vbanditv/how-to-add-fcm-firebase-cloud-messaging-to-vuejs-37np
 */

const firebaseConfig = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID
}

firebase.initializeApp(firebaseConfig)

const firebaseMessaging = firebase.messaging()
export default firebaseMessaging

export async function getTokenFCM() {
  // [START messaging_get_token]
  // Get registration token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  try {
    let vapidKey = process.env.VUE_APP_FIREBASE_PUBLIC_KEY

    let currentToken = await firebaseMessaging.getToken({
      vapidKey,
    })
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      // Get FCM token
      let fcmCookieName = process.env.VUE_APP_COOKIE_FCM_NAME
      let fcmCookieToken = getCookie(fcmCookieName)
      if (fcmCookieToken !== currentToken) {
        // IF value of fcm token is not the same, update to database
        await store.dispatch('firebaseToken/deleteToken', {token : fcmCookieToken});
        try {
          await store.dispatch('firebaseToken/storeFCMToken', {
            token: currentToken,
          })
        } catch (err) {
          console.log(err)
        }
      }
      return currentToken
    } else {
      // Show permission request UI
      console.log(
        'No registration token available. Request permission to generate one.'
      )
    }
  } catch (err) {
    console.log('An error occurred while retrieving token. ', err)
  }

  // [END messaging_get_token]
}

export async function deleteFCMToken() {
  // [START messaging_delete_token]
  try {
    let fcmCookieName = process.env.VUE_APP_COOKIE_FCM_NAME
    let fcmCookieToken = getCookie(fcmCookieName)
    await firebaseMessaging.deleteToken()
    await store.dispatch('firebaseToken/deleteToken', {token : fcmCookieToken})
  } catch (err) {
    console.log('Unable to delete fcm token.' )
    console.log(err)
  }
  // [END messaging_delete_token]
}

/*
 * This will be triggered when the browser is open
 */
export async function onReceivedMessage(callback) {
  firebaseMessaging.onMessage(function (payload) {
    if (callback) {
      callback(payload)
    }
    // this.notification.body = notification.body;
    // this.notification.title = notification.title;
  })
}

export function requestPermission() {
  // [START messaging_request_permission]
  return Notification.requestPermission()
    .then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.')
        return true
      } else {
        showToast(
          'Please Enable your browser notification',
          'is-danger',
          'is-bottom'
        )
        console.log('Unable to get permission to notify.')
        return false
      }
    })
    .catch((err) => {
      console.log(err)
      console.log('Unable to get permission to notify.')
      return false
    })
  // [END messaging_request_permission]
}

export function receiveMessage() {
  // [START messaging_receive_message]
  // Handle incoming messages. Called when:
  // - a message is received while the app has focus
  // - the user clicks on an app notification created by a service worker
  //   `messaging.onBackgroundMessage` handler.
  firebaseMessaging.onMessage((payload) => {
    console.log('Message received. ', payload)
    // ...
  })
  // [END messaging_receive_message]
}
