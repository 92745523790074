import ApiService from '@/services/common/api.service'

const reimbursement = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    reimbursementRequestData: [],
    reimbursementApprovalData: [],
    reimbursementTypeData: [],
  }),
  getters: {
    getReimbursementRequestData(state) {
      return state.reimbursementRequestData
    },
    getReimbursementApprovalData(state) {
      return state.reimbursementApprovalData
    },
    getReimbursementTypeData(state) {
      return state.reimbursementTypeData
    },
  },
  actions: {
    // To fetch request data
    fetchReimbursementRequestData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/reimburse/request', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setReimbursementRequestData', [
                ...context.state.reimbursementRequestData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To fetch reimburse type
    fetchReimbursementTypeData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/reimburse-type', {
          perPage: content.perPage,
          page: content.page,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setReimbursementTypeData', [
                ...context.state.reimbursementTypeData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    saveReimbursementTypeData(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.post('api/space-roketin/reimburse-type', form, true).then(
          (response) => {
            context.commit('setReimbursementTypeData', [
              ...context.state.reimbursementTypeData,
              response.data.data,
            ])
            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    editReimbursementTypeData(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.put(
          `/api/space-roketin/reimburse-type/${data.id}`,
          data,
          true
        ).then(
          (response) => {
            if (context?.state?.reimbursementTypeData) {
              const allData = context.state.reimbursementTypeData

              const filteredIndex = allData.findIndex((el) => el.id === data.id)

              const structuredData = {
                id: data.id,
                name: data.name,
                createdAt: data.createdAt,
                updatedAt: new Date(),
                companyId: data.companyId,
                companyName: data.companyName,
              }

              const result = [
                ...allData.slice(0, filteredIndex),
                structuredData,
                ...allData.slice(filteredIndex + 1),
              ]
              context.commit('setReimbursementTypeData', [...result])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    deleteReimbursementTypeData(context, id) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.delete(`/api/space-roketin/reimburse-type/${id}`).then(
          (response) => {
            if (context?.state?.reimbursementTypeData) {
              const data = context.state.reimbursementTypeData.filter(
                (el) => el.id !== id
              )
              context.commit('setReimbursementTypeData', [...data])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // To fetch managements' approval data
    fetchReimbursementApprovalData(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/reimburse/approval', {
          perPage: content.perPage,
          sortField: content.sortField,
          sortOrder: content.sortOrder,
          page: content.page,
          search: content.search,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setReimbursementApprovalData', [
                ...context.state.reimbursementApprovalData,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To create new WFO Permission request
    saveReimbursement(context, form) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        ApiService.post(
          `/api/space-roketin/reimburse/request`,
          form,
          true
        ).then(
          (response) => {
            context.commit('setReimbursementRequestData', [
              ...context.state.reimbursementRequestData,
              response.data.data,
            ])

            context.commit('setLoading', false)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // To update personal request
    updateReimbursementRequest(context, data) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.setHeaderMultipartFormData()

        const { id, form } = data

        ApiService.post(
          `/api/space-roketin/reimburse/request/${id}`,
          form,
          true
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.reimbursementRequestData
            ) {
              let ReimbursementRequestList = [
                ...context.state.reimbursementRequestData,
              ]

              let index = ReimbursementRequestList.findIndex((t) => t.id === id)

              if (index >= 0) {
                ReimbursementRequestList[index] = response.data.data
              }

              context.commit('setReimbursementRequestData', [
                ...ReimbursementRequestList,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    // To update approval status
    updateReimbursementApproval(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        const { id, status, reportToId } = content

        ApiService.put(`/api/space-roketin/reimburse/approval/${id}`, {
          status: status,
        }).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.reimbursementApprovalData
            ) {
              let reimbursementApprovalList = [
                ...context.state.reimbursementApprovalData,
              ]

              let index = reimbursementApprovalList.findIndex(
                (t) => t.id === id
              )

              if (index >= 0) {
                reimbursementApprovalList[index].approvalStatus = status

                let historyIndex = reimbursementApprovalList[
                  index
                ].history.findIndex((h) => h.reportToId === reportToId)

                if (historyIndex >= 0) {
                  // update history
                  reimbursementApprovalList[index].history[
                    historyIndex
                  ].status = status
                }
              }

              context.commit('updateApprovalStatus', [
                ...reimbursementApprovalList,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // To update approval status in bulk
    massUpdateReimbursementApproval(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { status, ids } = content

        ApiService.put('/api/space-roketin/reimburse/approval/mass-update', {
          status: status,
          ids: ids,
        }).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateApprovalStatus', {
                status,
                ids,
              })
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    setReimbursementRequestData(state, data) {
      state.reimbursementRequestData = data
    },
    setReimbursementTypeData(state, data) {
      state.reimbursementTypeData = data
    },
    setReimbursementApprovalData(state, data) {
      state.reimbursementApprovalData = data
    },

    updateApprovalStatus(state, data) {
      state.reimbursementApprovalData = data
    },

    massUpdateApprovalStatus(state, data) {
      const { status, ids } = data
      let reimbursementApprovalList = [...state.reimbursementApprovalData]

      reimbursementApprovalList.forEach((t) => {
        if (ids.includes(t.id)) {
          t.approvalStatus = status
        }
      })
      state.reimbursementApprovalData = reimbursementApprovalList
    },
    // For set loading
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default reimbursement
