import ApiService from '@/services/common/api.service'
import { formatDate } from '../../services/util'

const notifications = {
  namespaced: true,
  state: () => ({
    notifications: [],
  }),
  getters: {
    getNotifications(state) {
      return state.notifications
    },
  },
  actions: {
    // to fetch my team attendance data for a day
    fetchNotifications(context,params) {
      return new Promise((resolve, reject) => {
        ApiService.get(`/api/space-roketin/notification`,params).then(
          (response) => {
            context.commit('setNotification', response.data.data)

            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    updateRead(context, id) {
      return new Promise((resolve, reject) => {
        ApiService.post(`/api/space-roketin/notification/read-one`, {
          id,
        }).then(
          (response) => {
            context.commit('updateReadNotification', id)
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    readAll(context) {
      return new Promise((resolve, reject) => {
        ApiService.post(`/api/space-roketin/notification/read-all`, {}).then(
          (response) => {
            context.commit('updateReadAllNotification')
            resolve(response)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
  },
  mutations: {
    setNotification(state, data) {
      let temp = [...state.notifications]
      data.forEach((n) => {
        temp.push({
          id: n.id,
          message: n.note,
          date: formatDate(new Date(n.createdAt), 'MMM DD, YYYY'),
          time: formatDate(new Date(n.createdAt), 'HH:mm'),
          image: n.data.profilePictureUrl,
          category: n.moduleType,
          requestReason: n.data.requestReason,
          fullName: n.data.fullName,
          isRead: n.isRead,
        })
      })
      state.notifications = temp
    },
    updateReadNotification(state, id) {
      // const { id } = data
      //
      // let updated = JSON.parse(JSON.stringify(data))
      // updated.status = 1
      let index = state.notifications.findIndex((e) => e.id === id)
      state.notifications[index].isRead = 1
    },
    updateReadAllNotification(state) {
      let temp = [...state.notifications]
      temp.forEach((t) => (t.isRead = 1))
      state.notifications = temp
    },
  },
}

export default notifications
