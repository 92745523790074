import Vue from 'vue'
import Vuex from 'vuex'

import changeSchedule from './modules/changeSchedule.module'
import overtimeModule from './modules/overtime.module'
import logActivity from './modules/logActivity.module'
import reimbursement from './modules/reimbursement.module'
import timeOff from './modules/timeOff'
import timeOffModule from './modules/timeOff.module'
import attendance from './modules/attendance.module'
import outstandingRequest from './modules/outstandingRequest.module'
import employment from './modules/employment.module'
import auth from './modules/auth'
import settings from './modules/settings.module'
import user from './modules/user.module'
import wfoPermission from './modules/wfoPermission.module'
import finalApproval from './modules/finalApproval.module'
import allowanceModule from './modules/allowance.module'
import activityAttendance from './modules/activityAttendance.module'
import announcement from './modules/announcement.module'
import quotes from './modules/quotes.module'

import firebaseToken from './modules/firebaseToken.module'
import notifications from './modules/notification.module'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    changeSchedule,
    overtimeModule,
    outstandingRequest,
    logActivity,
    reimbursement,
    timeOff,
    timeOffModule,
    attendance,
    employment,
    auth,
    settings,
    user,
    wfoPermission,
    finalApproval,
    firebaseToken,
    notifications,
    allowanceModule,
    activityAttendance,
    announcement,
    quotes,
  },
})

export default store
