import ApiService from '@/services/common/api.service'

const activityAttendance = {
  namespaced: true,
  state: () => ({
    errors: null,
    isLoading: false,
    activityAttendancePersonal: [],
    activityAttendanceTeam: [],
    activityAttendanceRise: [],
  }),
  getters: {
    getActivityAttendancePersonal(state) {
      return state.activityAttendancePersonal
    },
    getActivityAttendanceTeam(state) {
      return state.activityAttendanceTeam
    },
    getActivityAttendanceRise(state) {
      return state.activityAttendanceRise
    },
  },

  actions: {
    // fetch activity attendance personal data
    fetchActivityAttendancePersonal(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          '/api/space-roketin/v3/attendance-activity-adjustment/request',
          {
            sortField: content.sortField,
            sortOrder: content.sortOrder,
            perPage: content.perPage,
            page: content.page,
            search: content.search,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setActivityAttendancePersonal', [
                ...context.state.activityAttendancePersonal,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    // fetch activity attendance team data
    fetchActivityAttendanceTeam(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get(
          '/api/space-roketin/v3/attendance-activity-adjustment/approval',
          {
            perPage: content.perPage,
            sortField: content.sortField,
            sortOrder: content.sortOrder,
            page: content.page,
            search: content.search,
          }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('setActivityAttendanceTeam', [
                ...context.state.activityAttendanceTeam,
                ...response.data.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    fetchActivityAttendanceRise(context) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        ApiService.get('/api/space-roketin/attendance-activity/universe').then(
          (response) => {
            if (response.status === 200) {
              context.commit('setActivityAttendanceRise', [
                ...context.state.activityAttendanceRise,
                ...response.data,
              ])

              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    updateLogActivityApprovalRequests(context, content) {
      return new Promise((resolve, reject) => {
        ApiService.setHeaderMultipartFormData()
        context.commit('setLoading', true)

        const { id, status, reportToId } = content

        ApiService.put(
          `/api/space-roketin/v3/attendance-activity-adjustment/approval/${id}`,
          {
            status: status,
          }
        ).then(
          (response) => {
            if (
              response.status === 200 &&
              context?.state?.activityAttendanceTeam
            ) {
              let activityResponseTeamList = [
                ...context.state.activityAttendanceTeam,
              ]

              let index = activityResponseTeamList.findIndex((t) => t.id === id)

              if (index >= 0) {
                activityResponseTeamList[index].status = status

                let historyIndex = activityResponseTeamList[
                  index
                ].history.findIndex((h) => h.reportToId === reportToId)

                if (historyIndex >= 0) {
                  // update history
                  activityResponseTeamList[index].history[
                    historyIndex
                  ].status = status
                }
              }
              context.commit('updateApprovalStatus', [
                ...activityResponseTeamList,
              ])
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading', false)
            reject(error)
          }
        )
      })
    },

    getTotalActivityDuration() {
      return new Promise((resolve, reject) => {
        ApiService.get('/api/space-roketin/attendance-activity/universe').then(
          (response) => {
            response.data[0].totalActivityDurationPerDay
              ? response.data[0].totalActivityDurationPerDay
              : 0
          },
          (error) => {
            reject(error)
          }
        )
      })
    },

    massUpdateActivityApprovalRequests(context, content) {
      return new Promise((resolve, reject) => {
        context.commit('setLoading', true)

        const { status, approvalIds } = content

        ApiService.put(
          '/api/space-roketin/v3/attendance-activity-adjustment/approval/bulk',
          { status: status, approval_ids: approvalIds }
        ).then(
          (response) => {
            if (response.status === 200) {
              context.commit('massUpdateApprovalStatus', {
                status,
                approvalIds,
              })
              context.commit('setLoading', false)
              resolve(response)
            }
          },
          (error) => {
            context.commit('setLoading, false')
            reject(error)
          }
        )
      })
    },
  },

  mutations: {
    setActivityAttendancePersonal(state, data) {
      state.activityAttendancePersonal = data
    },
    setActivityAttendanceTeam(state, data) {
      state.activityAttendanceTeam = data
    },
    setActivityAttendanceRise(state, data) {
      // add isLoading property to each data to controll toggle play/pause loading state
      data.forEach((d) => {
        d.data.forEach((t) => (t.isLoading = false))
      })
      state.activityAttendanceRise = data
    },
    updateApprovalStatus(state, data) {
      state.activityAttendanceTeam = data
    },
    massUpdateApprovalStatus(state, data) {
      const { status, approvalIds } = data
      let activityResponseTeamList = [...state.activityAttendanceTeam]

      activityResponseTeamList.forEach((t) => {
        if (approvalIds.includes(t.id)) {
          t.status = status
        }
      })
      state.activityAttendanceTeam = activityResponseTeamList
    },
    setLoading(state, data) {
      state.isLoading = data
    },
  },
}

export default activityAttendance
